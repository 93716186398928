<template>
  <div>
    <b-card-code style="display: flex; flex-direction: column" title="ثبت دسته بندی جدید">
      <validation-observer ref="loginForm" #default="{ invalid }">
        <b-row>
          <b-col md="6" class="mb-1">
            <b-form-group label-for="basicInput">
              <h4>عنوان دسته بندی</h4>
              <validation-provider #default="{ errors }" name="title" rules="required">
                <b-form-input name="title" id="title" v-model="formData.title" :state="errors.length > 0 ? false : null"
                  placeholder="عنوان دسته بندی" />
                <small class="text-danger">{{
                  errors[0] ? "این فیلد الزامی است" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" class="mb-1">
            <b-form-group label-for="basicInput">
              <h4>اسلاگ</h4>
              <validation-provider #default="{ errors }" name="slug" rules="required">
                <b-form-input name="slug" id="slug" v-model="formData.slug" :state="errors.length > 0 ? false : null"
                  placeholder="اسلاگ" />
                <small class="text-danger">{{
                  errors[0] ? "این فیلد الزامی است" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="p-0 mb-1" md="6" style="padding: 0 14px !important">
            <h4>زیر مجموعه کدام دسته بندی ها</h4>
            <div class="d-flex align-items-center justify-content-end">
              <v-select dir="rtl" v-model="formData.parent_id" :options="parentIdOptions" :reduce="(item) => item.value"
                @search="searchAllCategories" class="w-100 border"
                style="border-radius: 5.712px; border-color: #d8d6de !important">
                <span slot="no-options">موردی یافت نشد.</span>
              </v-select>
            </div>
          </b-col>
        </b-row>

        <!-- uploader & cropper -->
        <!-- <b-row>
          <b-col
            md="6"
            class="mb-1"
            dir="ltr"
            style="direction: ltr !important"
          >
            <p>ویرایشگر تصویر</p>
            <vue-cropper
              v-if="imgSrc"
              dir="ltr"
              style="direction: ltr !important; flex-direction: row-reverse"
              ref="cropper"
              :aspect-ratio="1 / 1"
              :src="imgSrc ? imgSrc : ''"
              :autoCrop="false"
              @cropend="cropImage"
              outputType="image/jpg"
            />
            <div v-else class="crop-placeholder rounded"></div>
          </b-col>
          <b-col md="6" class="mb-1 d-flex flex-column justify-content-between">
            <div class="">
              <p>ویرایشگر تصویر</p>
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile01"
                ref="file"
                placeholder="فایل خود را انتخاب کنید..."
                accept=".jpeg,.jpg"
                @change="onChange()"
                style="margin: 36px 12px 0 12px"
              />
              <label
                class="custom-file-label"
                for="inputGroupFile01"
                style="margin: 36px 12px 0 12px"
                >{{
                  files[0] ? files[0].name : "فایل خود را انتخاب کنید..."
                }}</label
              >
            </div>
            <div>
              <b-button
                class="mt-1"
                variant="primary"
                :disabled="imgSrc == ''"
                @click.prevent="reset"
              >
                <span class="text-nowrap">ریست تصویر</span>
              </b-button> -->
        <!-- <b-button class="ml-1 mr-1 mt-1" variant="primary" :disabled="imgSrc == ''" @click.prevent="cropImage">
                <span class="text-nowrap">بریدن تصویر</span>
              </b-button> -->
        <!-- <b-button
                class="mt-1 ml-1"
                variant="primary"
                :disabled="!!croppedFile == false"
                @click.prevent="thumbnailUpload"
              >
                <span class="text-nowrap">آپلود تصویر</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->

        <!-- Complete explanations -->
        <b-row>
          <b-col cols="12" class="mb-1">
            <h4>توضیحات کامل</h4>
            <tiptap :order="1" v-model="formData.body" />
          </b-col>
        </b-row>

        <!-- Complete explanations -->
        <b-row>
          <b-col cols="12" class="mb-1">
            <h4>توضیحات</h4>
            <validation-provider name="description" rules="required">
              <b-form-textarea name="description" id="textarea-no-resize"
                :state="formData.description.length > 150 ? false : null"
                placeholder="توضیحات مختصری برای محصول خود وارد کنید، این توضیحات باید حداکثر 150 کاراکتر باشد" rows="3"
                v-model="formData.description" no-resize />
            </validation-provider>
          </b-col>
        </b-row>

        <b-button class="my-2 d-flex justify-content-center align-items-center" :disabled="isLoading" variant="primary"
          @click="createCategories()">
          ثبت
          <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
        </b-button>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code";
import {
  BFormInput,
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BFormSelect,
  // BProgress,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import router from '@/router';
import Tiptap from '@/components/Tiptap.vue'

export default {
  components: {
    Tiptap,
    VueCropper,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BSpinner,
    BButton,
    BFormSelect,
    // BProgress,
    BFormFile,
    ToastificationContent,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      required,
      attribute: null,
      imgSrc: "",
      croppedFile: null,
      files: [],
      file: "",
      tempAttachmentId: null,
      tempAttachmentName: null,
      formData: {
        title: "",
        slug: "",
        description: "",
        body: ``,
        thumbnail_id: null,
        attributes: null,
        is_special: 0,
        status: 1,
        parent_id: null,
      },
      parentIdOptions: [],
      cropLoading: true,
    };
  },
  methods: {
    searchAllCategories: debounce(function (e) {
      this.getAllCategories(e);
    }, 500),
    getAllCategories(titleText = "") {
      axios
        .get("/api/v1/admin/categories", { params: { title: titleText } })
        .then((response) => {
          let categoriesTray = response.data.data;
          this.parentIdOptions = [];
          categoriesTray.forEach((item) => {
            this.parentIdOptions.push({
              label: item.title,
              value: item.id,
            });

            // including childs
            if (item.childs.length > 0) {
              for (let index = 0; index < item.childs.length; index++) {
                const element = item.childs[index];

                this.parentIdOptions.push({
                  label: element.title,
                  value: element.id,
                });
                // including childs of child
                if (element.childs.length > 0) {
                  for (let index = 0; index < element.childs.length; index++) {
                    const elementTwo = element.childs[index]

                    this.parentIdOptions.push({
                      label: elementTwo.title,
                      value: elementTwo.id,
                    });
                  }
                }
              }
            }
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی در لیست دسته بندی ها پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    cropImage() {
      // this.cropLoading = true
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.croppedFile = blob;
      }, "image/jpeg");
    },
    reset() {
      this.$refs.cropper.reset();
    },
    thumbnailUpload() {
      setTimeout(() => {
        this.tempAttachmentId = null;
        const attachmentFormaData = new FormData();
        if (this.files[0]) {
          // let tempFiles = this.files;
          // attachmentFormaData.append("thumbnail", tempFiles[0]);
          attachmentFormaData.append(
            "thumbnail",
            this.croppedFile,
            this.files[0].name
          );
        }
        // this.tempAttachmentName = attachmentFormaData.get("attachment").name
        axios
          .post(
            "/api/v1/admin/categories/thumbnails/upload",
            attachmentFormaData
          )
          .then((res) => {
            this.tempAttachmentId = res.data.data.thumbnail_id;
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "اخطار",
                icon: "XOctagonIcon",
                text: "فایل بارگذازی نشد. لطفا دوباره تلاش کنید.",
                variant: "danger",
              },
            });
          });
      }, 2000);
    },
    onChange() {
      if (this.$refs.file.files[0].size / (1024 * 1024) > 5) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "اخطار",
            icon: "XOctagonIcon",
            text: "حجم فایل انتخاب شده بیش از حد مجاز است. (5MB)",
          },
        });
      } else {
        this.files = [...this.$refs.file.files];
        if (typeof FileReader === "function") {
          const reader = new FileReader();

          reader.onload = (event) => {
            this.imgSrc = event.target.result;
            this.$refs.cropper.replace(event.target.result);
          };

          reader.readAsDataURL(this.files[0]);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "فایل مورد نظر پشتیبانی نمیشود.",
            },
          });
        }
      }
    },
    createCategories() {
      this.formData.thumbnail_id = this.tempAttachmentId;
      if (this.attribute) {
        this.formData.attributes = [this.attribute];
      }
      if (this.formData.title != "" && this.formData.slug != "") {
      this.isLoading = true;
        axios
          .post("/api/v1/admin/categories/create", this.formData)
          .then((res) => {
            // this.formData.title = "";
            // this.formData.slug = "";
            // this.formData.description = "";
            // this.formData.body = ``;
            // this.formData.thumbnail_id = null;
            // this.formData.attributes = null;
            // this.formData.parent_id = null;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "دسته بندی اضافه شد",
                icon: "CheckIcon",
                text: "دسته بندی با موفیقت اضافه شد",
                variant: "success",
              },
            });

            this.isLoading = false;
            router.push("/categories")
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "اخطار",
                icon: "XOctagonIcon",
                text: "مشکلی پیش آمده!",
                variant: "danger",
              },
            });
          });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "لطفا فیلد ها را پر کنید",
            variant: "info",
            icon: "XOctagonIcon",
          },
        });
      }
    },
  },
  mounted() {
    this.getAllCategories();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.crop-placeholder {
  height: 291px;
  background: #ccc;
}

.cropped-image {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cropped-image img {
  max-width: 100%;
  // max-height: 400px;
}

.cropper-crop-box {
  display: flex;
}

.cropper-container {
  direction: ltr !important;
  // max-height: 291px !important;
  overflow: hidden;
}

.cropper-bg {
  direction: ltr !important;
}

[dir="rtl"] .cropper-container {
  direction: ltr !important;
}
</style>
